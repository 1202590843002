import React, { useContext, useEffect, useState } from "react";
import AppContext from "../context/app";
import EASC from "../contracts/easc.js";
import $ from 'jquery'; 

import Login from '../components/Login'
function Home() {
    const { user, token } = useContext(AppContext);
    const [mintedText, setMintedText] = useState("");
    const [apeId, setApeId] = useState("");
    const setApeIdValue = (e) => {
        setApeId(e.target.value);
    }
    const checkApeId = async () => {
        if (!(apeId && apeId != '' && parseInt(apeId) >= 0 && parseInt(apeId) <= 6969)) {
            setMintedText("💩")
            return
        }
        const l1Id = await EASC.methods.mutatedL1s(apeId).call();
        const l2Id = await EASC.methods.mutatedL2s(apeId).call();
        console.error(l1Id, l2Id);
        if (l1Id == 0 && l2Id > 0) {
            setMintedText("Apez ate L2");
        }
        if (l1Id > 0 && l2Id == 0) {
            setMintedText("Apez ate L1");
        }
        if (l1Id > 0 && l2Id > 0) {
            setMintedText("Apez ate L1 and L2");
        }
        if (l1Id == 0 && l2Id == 0) {
            setMintedText("Apez no eat anything");
        }
    }

    
    useEffect(() => {
        (function($) {
            "use strict";
          
            // Preloader
            $(window).on('load', function() {
              if ($('#preloader').length) {
                $('#preloader').delay(100).fadeOut('slow', function() {
                  $(this).remove();
                });
              }
            });
          
            // Smooth scroll for the navigation menu and links with .scrollto classes
            var scrolltoOffset = $('#header').outerHeight() - 2;
            $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function(e) {
              if (window.location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && window.location.hostname == this.hostname) {
                var target = $(this.hash);
                if (target.length) {
                  e.preventDefault();
          
                  var scrollto = target.offset().top - scrolltoOffset;
          
                  if ($(this).attr("href") == '#header') {
                    scrollto = 0;
                  }
          
                  $('html, body').animate({
                    scrollTop: scrollto
                  }, 1500, 'easeInOutExpo');
          
                  if ($(this).parents('.nav-menu, .mobile-nav').length) {
                    $('.nav-menu .active, .mobile-nav .active').removeClass('active');
                    $(this).closest('li').addClass('active');
                  }
          
                  if ($('body').hasClass('mobile-nav-active')) {
                    $('body').removeClass('mobile-nav-active');
                    $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
                    $('.mobile-nav-overly').fadeOut();
                  }
                  return false;
                }
              }
            });
          
            // Activate smooth scroll on page load with hash links in the url
            $(document).ready(function() {
              if (window.location.hash) {
                var initial_nav = window.location.hash;
                if ($(initial_nav).length) {
                  var scrollto = $(initial_nav).offset().top - scrolltoOffset;
                  $('html, body').animate({
                    scrollTop: scrollto
                  }, 1500, 'easeInOutExpo');
                }
              }
            });
          
            // Mobile Navigation
            if ($('.nav-menu').length) {
              var $mobile_nav = $('.nav-menu').clone().prop({
                class: 'mobile-nav d-lg-none'
              });
              $('body').append($mobile_nav);
              $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="fa fa-bars"></i></button>');
              $('body').append('<div class="mobile-nav-overly"></div>');
          
              $(document).on('click', '.mobile-nav-toggle', function(e) {
                $('body').toggleClass('mobile-nav-active');
                $('.mobile-nav-toggle i').toggleClass('fa fa-bars fa fa-close');
                $('.mobile-nav-overly').toggle();
              });
          
              $(document).on('click', '.mobile-nav .drop-down > a', function(e) {
                e.preventDefault();
                $(this).next().slideToggle(300);
                $(this).parent().toggleClass('active');
              });
          
              $(document).click(function(e) {
                var container = $(".mobile-nav, .mobile-nav-toggle");
                if (!container.is(e.target) && container.has(e.target).length === 0) {
                  if ($('body').hasClass('mobile-nav-active')) {
                    $('body').removeClass('mobile-nav-active');
                    $('.mobile-nav-toggle i').toggleClass('fa fa-bars fa fa-close');
                    $('.mobile-nav-overly').fadeOut();
                  }
                }
              });
            } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
              $(".mobile-nav, .mobile-nav-toggle").hide();
            }
          
            // Navigation active state on scroll
            var nav_sections = $('section');
            var main_nav = $('.nav-menu, #mobile-nav');
          
            $(window).on('scroll', function() {
              var cur_pos = $(this).scrollTop() + 200;
          
              nav_sections.each(function() {
                var top = $(this).offset().top,
                  bottom = top + $(this).outerHeight();
          
                if (cur_pos >= top && cur_pos <= bottom) {
                  if (cur_pos <= bottom) {
                    main_nav.find('li').removeClass('active');
                  }
                  main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
                }
                if (cur_pos < 300) {
                  $(".nav-menu ul:first li:first").addClass('active');
                }
              });
            });
          
            // Toggle .header-scrolled class to #header when page is scrolled
            $(window).scroll(function() {
              if ($(this).scrollTop() > 100) {
                $('#header').addClass('header-scrolled');
              } else {
                $('#header').removeClass('header-scrolled');
              }
            });
          
            if ($(window).scrollTop() > 100) {
              $('#header').addClass('header-scrolled');
            }
          
           
          
          })($);
    });
    return (
        <React.Fragment>
            <body>
                <button type="button" class="mobile-nav-toggle d-lg-none"><i class="fa fa-bars"></i></button>
                <header id="header">
                    <div className="container d-flex align-items-center">

                        <h1 className="logo mr-4"><a href="/"></a></h1>
                        <a href="/" className="logo mr-auto"><img src="assets/img/logo.png" alt="" className="img-fluid" /></a>

                        <div className="form-group has-search mr-auto">

                        </div>
                        <nav className="nav-menu d-none d-lg-block ml-4 pl-1 pr-4 bg-light rounded">
                            <ul>
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <li>
                                    <a href="/comics">Comics</a>
                                </li>
                                <li><a href="/enlightenment">
                                    <div className="col">
                                        <button className="rounded-pill btn btn-primary btn-join" style={{padding: "10px"}}>Halloween 🎃 Enlightenment</button>
                                    </div></a>
                                </li>
                            </ul>
                            <ul>

                            </ul>
                        </nav>



                    </div>
                </header>
                <div id="hero">
                    <div className="d-flex align-items-center">
                        <div className="container">
                            <img src="assets/img/c.png" className="img-fluid" />
                            <div className="row">
                                <div className="bottom-left">
                                    <h2>Welcome to</h2>
                                    <h1>Stoned 🍄 Apez</h1>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <main id="main">

                    <section id="about" className="about">
                        <div className="container">

                            <div className="row content">
                                <div className="col-lg-8">
                                    <p>
                                        Stoned Apez is a new way of building a community and making them aware of NFT and spread more art to feel more inclusive and don't have <span className="turquoise"> "FOMO"
                                        </span></p>
                                    <p className="pt-4">
                                        <span className="turquoise">Stoned Apez </span> is planning to go to <span className="turquoise"> Saturn - </span> What? Saturn! Why? "Guess All are stoned or there is something these stoned Apez are trying to do.
                                    </p>
                                    <p className="pt-4">
                                        Our clubhouse to open as we hit sell out - Merchandises includes <span className="turquoise">sweatshirts, Stickers, one Surprise card </span>and if you like to get stoned start collecting your shrooms <span className="turquoise">we have Halloween party coming </span> So try to have S<del>e</del>ix with us.
                                    </p>
                                    <p style={{ color: "#fb536d" }}><i>Note: A few of perks will be withheld from the sale. These will be used for giveaways, rewards and for the creators, Artist and Developer' Stoned Apez club memberships.</i></p>

                                </div>
                                <div className="col-lg-4 pt-4 pt-lg-0">
                                    <img src="assets/img/logo2.png" className="img-fluid" />
                                </div>
                            </div>

                        </div>
                    </section>
                    <section id="hero" className="teambannerbg">

                        <div className="container">

                            <div className="row d-flex align-items-center">
                                <div className="col-md-4 mx-auto">

                                    <h1><span className="font800"> Let's get Stoned and C<del>u</del>ome together...</span> </h1>
                                    <div className="line mb-4"></div>
                                    <p>Welcome to the club! We have a total of <span className="turquoise">6969</span>Stoned Apez in total randomly generated from over 69 assets c<del>u</del>oming together as atoms and form some good compound. There are <span className="turquoise">4200</span> male and <span className="turquoise">2769</span> female character who want to c<del>u</del>ome
                                    </p></div>
                                <div className="col-md-4 mx-auto">
                                    <img src="assets/img/club1.png" className="img-fluid rounded" />
                                </div>
                                <div className="col-md-4 mx-auto">
                                    <img src="assets/img/club2.png" className="img-fluid rounded pb-5" />
                                    <p>You just bought a jpeg? No...No... Just hold on to have 6 with us and see what it does.</p>
                                    <img src="assets/img/club3.png" className="img-fluid rounded" />
                                </div>
                            </div>



                        </div>
                    </section>

                    <section>
                        <div className="container">
                            <div className="join">
                                <div className="row">
                                    <div className="d-flex align-items-center">
                                        <div className="col-lg-3 col-md-3 col-3">
                                            <div className="section-title text-left mt-4">
                                                <h2>Sold Out</h2>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-md-7 col-6 pl-4 content">
                                            <p>
                                                The 🛍️ stoned Apez are sold out. Now you can purchase and join in the community from open sea
                                            </p>

                                        </div>
                                        <div className="col-lg-2 col-md-2 col-3 content">
                                            <a href="https://opensea.io/collection/stoned-ape-saturn-club">
                                                <button className="rounded btn btn-primary btn-1">check on Open Sea</button>
                                            </a>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </section>

                    <div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="line"></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="line"></div>
                                    </div>
                                </div>
                            </div>

                            <section id="p2" className="p2">
                                <div className="container">

                                    <div className="row content">
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="section-title text-left">
                                                <h1>☄️SPACEMAP ACTIVATION</h1>

                                                We’re in this for the long haul.<br /><br />

                                                We’ve set up some goalposts for ourselves. Once we hit a target sell through percentage, we will begin to work on realizing the stated goal.
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 pt-4 pt-lg-0">
                                            <img src="assets/img/comic.png" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-7 pl-5">
                                            <div className="table-responsive">
                                                <span className="turquoise"> <strike><h5>Phase 1 </h5> </strike></span><div className="col-lg-7 pl-5">
                                                    <div className="table-responsive">
                                                    </div></div><table className="table table-borderless">

                                                    <tbody>


                                                        <strike><tr>
                                                            <td className="textper">20%</td>
                                                            <td>2 holder 🧑🏻‍🤝‍🧑🏻 will win an additional NFT 🐤</td>

                                                        </tr></strike>
                                                        <strike><tr>
                                                            <td className="textper">50%</td>
                                                            <td className="text-black">5 Holders 🧑🏻‍🤝‍🧑🏻 win an additional NFT 🐤</td>

                                                        </tr></strike>
                                                        <strike><tr>
                                                            <td className="textper">75%</td>
                                                            <td className="text-black">Sold 7 Holders win an additional NFT and 1 winner
                                                                gets .5 eth</td>

                                                        </tr>
                                                        </strike>

                                                        <strike><tr>
                                                            <td className="textper"></td>
                                                            <td className="text-black"><p style={{ color: "#fb536d" }}>Sold out 1 idea, 10 nfts (1 winner gets .5 eth and a trip for 2,  3 days 2 night in LA!)</p></td>

                                                        </tr></strike></tbody></table><span className="turquoise"> <h5>Phase 2 </h5> </span><div className="col-lg-7 pl-5">
                                                    <div className="table-responsive">
                                                    </div></div><table className="table table-borderless">

                                                    <tbody>


                                                        <tr>
                                                            <td className="textper"></td>
                                                            <td className="text-black">🎃Halloween Surprise🎉 (Mutate?...IDK👻)</td>

                                                        </tr>
                                                        <tr>
                                                            <td className="textper"></td>
                                                            <td className="text-black">Monthly Comics - How they travelled to Saturn? How will they survive? The journey to Saturn is going to be fun.🪐</td>

                                                        </tr>



                                                    </tbody></table><span className="turquoise"> <h5>Phase 3 </h5> </span><div className="col-lg-7 pl-5">
                                                    <div className="table-responsive">
                                                    </div></div><table className="table table-borderless">

                                                    <tbody>


                                                        <tr>
                                                            <td className="textper"></td>
                                                            <td className="text-black">Decentaland/crypto Voxels/Sandbox We aren't just working to give .Jpeg</td>




                                                        </tr></tbody></table><span className="turquoise"> <h5>Phase 4 </h5> </span><table className="table table-borderless">

                                                    <tbody>


                                                        <tr>
                                                            <td className="textper"></td>
                                                            <td className="text-black">IRL Meetup and Smoke party (LA , Amsterdam and other places as suggested by community</td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </section>
                            <section id="team" className="team section-bg">
                                <div className="container">

                                    <div className="section-title">
                                        <h2 className="black text-left">The Team</h2>
                                    </div>

                                    <div className="row">

                                        <div className="col-lg-3 col-md-6 d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
                                            <div className="member">
                                                <div className="member-img">
                                                    <img src="assets/img/tm1.jpg" className="img-fluid" alt="" />

                                                </div>
                                                <div>
                                                    <div className="member-info">
                                                        <h4>Yuan: keyboard Basher</h4>
                                                        <h6>The true heart of the project, Yuan and his development team make the dreams real</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-lg-3 col-md-6 d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
                                            <div className="member">
                                                <div className="member-img">
                                                    <img src="assets/img/tmx.jpeg" className="img-fluid" alt="" />

                                                </div>

                                                <div>
                                                    <div className="member-info">
                                                        <h4>Arcapol</h4>
                                                        <h6>Foobar Barfoo</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-md-6 d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
                                            <div className="member">
                                                <div className="member-img">
                                                    <img src="assets/img/tm4.jpg" className="img-fluid" alt="" />
                                                </div>

                                                <div>
                                                    <div className="member-info">
                                                        <h4>Chris: the Big Brother</h4>
                                                        <h6>The cool kind that parties with you but keeps you out of jail. Chris will help keep the apez focused and build the community.</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
                                            <div className="member">
                                                <div className="member-img">
                                                    <img src="assets/img/tm5.jpg" className="img-fluid" alt="" />

                                                </div>
                                                <div>
                                                    <div className="member-info">
                                                        <h4>Arasu - Fibonacci dragon</h4>
                                                        <h6>The guy who adds 01 in 2D</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
                                            <div className="member">
                                                <div className="member-img">
                                                    <img src="assets/img/tm6.jpg" className="img-fluid" alt="" />

                                                </div>
                                                <div>
                                                    <div className="member-info">
                                                        <h4>Chandravijay</h4>
                                                        <h6>Illustrator who likes creating some easters egg</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <section className="section-bg">
                                    <div className="container">
                                        <div className="productex col-md-6 mx-auto">
                                            <div className="row">

                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <div className="section-title text-center">
                                                        <center><span className="turquoise"> <h5>Wondering if you Epez has already consumed the mushroom? No problem. Just Enter your SASC token number and check if it has been used </h5> </span></center>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">

                                                <div className="col-lg-12 col-md-12 col-12">

                                                    <div className="form-inline justify-content-center">
                                                        <div className="form-group mb-2">
                                                            <input type="number" max="6969"  className="form-control textbox" id="" onChange={setApeIdValue} />
                                                        </div>

                                                        <button className="rounded btn btn-primary mb-2 btn-3" onClick={checkApeId}>Check Now</button>
                                                        <div className="minted">{mintedText}</div>
                                                    </div>

                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </section>
                                <section className="faq_content collaps_style section_padding ft_font">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-sm-10">
                                                <div className="section_tittle style_2">
                                                    <h2 className="wow fadeInDown" data-wow-delay=".5s">General FAQ’s </h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-12">
                                                <div className="faq_arrordion_content">
                                                    <div className="accordion" id="faq_arrordion">
                                                        <div className="card wow fadeInLeft" data-wow-delay=".4s">
                                                            <div className="card-header" id="faq_tittle_2">
                                                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#faq_2" aria-expanded="false" aria-controls="faq_2">
                                                                    What is Open Sea?
                                                                </button>
                                                            </div>
                                                            <div id="faq_2" className="collapse" data-parent="#faq_arrordion">
                                                                <div className="card-body">
                                                                    OpenSea peer-to-peer marketplace for NFTs, rare digital items, and crypto collectibles. It’s the place where you can find your Stoned Apez NFTs once they sell out like hotcakes🥞, This is also the primary place where you can purchase a Stoned Apez NFT once they’ve sold out.

                                                                    Think of OpenSea as a market that allows you to view, buy, and sell your NFTs. This applies to both Stoned Apez NFTs and other digital collectibles in your wallet.

                                                                    Once you mint your Stoned Apez NFT, it will appear in your wallet, and you can view it on OpenSea.

                                                                    To get on OpenSea, you’ll have to visit their official website at www.opensea.io After getting on the website, you’ll have to connect your wallet by pressing the wallet-shaped button in the top right corner. After pressing the button, you’ll get a pop-up on your metamask to confirm the connection. After confirmation, you can visit your wallet and explore your NFTs.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card wow fadeInLeft" data-wow-delay=".3s">
                                                            <div className="card-header" id="faq_tittle_1">
                                                                <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#faq_1" aria-expanded="true" aria-controls="faq_1">
                                                                    What are NFT's?
                                                                </button>
                                                            </div>

                                                            <div id="faq_1" className="collapse show" aria-labelledby="faq_tittle_1" data-parent="#faq_arrordion">
                                                                <div className="card-body">
                                                                    Non-fungible token.That doesn’t make it any clearer.Right, sorry. “Non-fungible” more or less means that it’s unique and can’t be replaced with something else. For example, a bitcoin is fungible — trade one for another bitcoin, and you’ll have exactly the same thing. A one-of-a-kind trading card, however, is non-fungible. If you traded it for a different card, you’d have something completely different.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card wow fadeInLeft" data-wow-delay=".5s">
                                                            <div className="card-header" id="faq_tittle_3">
                                                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#faq_3" aria-expanded="false" aria-controls="faq_3">
                                                                    How do NFTs work?
                                                                </button>
                                                            </div>
                                                            <div id="faq_3" className="collapse" aria-labelledby="faq_tittle_3" data-parent="#faq_arrordion">
                                                                <div className="card-body">
                                                                    NFTs are part of the Ethereum blockchain so they are individual tokens with extra information stored in them. That extra information is the important part, which allows them to take the form of art, music, video (and so on), in the form of JPGS, MP3s, videos, GIFs and more. Because they hold value, they can be bought and sold just like other types of art – and, like with physical art, the value is largely set by the market and by demand.

                                                                    That's not to say there's only one digital version of an NFT art available on the marketplace, though. In much the same way as art prints of an original are made, used, bought and sold, copies of an NFT are still valid parts of the blockchain – but they will not hold the same value as the original.

                                                                    And don't go thinking you've hacked the system by right-clicking and saving the image of an NFT, either. That won't make you a millionaire because your downloaded file won't hold the information that makes it part of the Ethereum blockchain. Make sense?
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card wow fadeInLeft" data-wow-delay=".6s">
                                                            <div className="card-header" id="faq_tittle_4">
                                                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#faq_4" aria-expanded="false" aria-controls="faq_4">
                                                                    What is the minting process?
                                                                </button>
                                                            </div>
                                                            <div id="faq_4" className="collapse" aria-labelledby="faq_tittle_4" data-parent="#faq_arrordion">
                                                                <div className="card-body">
                                                                    Minting an NFT is simple; it means that you’re minting an NFT on the ERC721 blockchain, and to mint your Stoned Apez, all you need to do is follow a few simple steps.

                                                                    The first thing you’ll want to do is head to the official Stoned Apez website at www.stonedapez.ai. Once you’re on the website you will see mint stoned apez, clicking on that will take to new page if minting is live, a pop-up will appear, allowing you to connect your metamask wallet with the website, which you should accept.

                                                                    Once your wallet and the website are connected, you can navigate towards the ‘mint’ section. This will let you choose the amount of Stoned Apez NFTs you want to mint. Remember, you can’t mint more than 9 NFTs from one wallet.

                                                                    After selecting the amount you’d like to mint and press the ‘Mint’ button, you’ll get a transaction pop-up in your metamask. If you have enough ETH to process the transaction, you can accept, wait for it to go through, and you’re set!

                                                                    Remember, you’ll need enough ETH to handle the price of your mint which is 0.024 and the current gas price.
                                                                    Note : The current minting is supported by metamask only and on Chrome Browser
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section></section></div>
                    </div>

                </main>

                <footer id="footer">


                    <div className="container py-4">

                        <div className="mr-md-auto text-center">
                            <div className="copyright">
                                VERIFIED SMART CONTRACT ADDRESS:<a href="https://etherscan.io/address/0x6dFCB04b7d2aB2069d9ba81aC643556429EB2d55"> <strong><span className="turquoise">0x6dFCB04b7d2aB2069d9ba81aC643556429EB2d55</span></strong></a>.
                            </div>
                            <div className="copyright">
                                For any query or collaboration reach out on <h5>help@stonedapez.club</h5>
                            </div>
                        </div>
                        <div className="social-links text-center text-md-center pt-3 pt-md-0">

                            <a href="https://discord.gg/5bxTStNdt8" className="discord"><i className="fa fa-discord"></i></a>
                            <a href="https://twitter.com/StonedApez" className="twitter"><i className="fa fa-twitter"></i></a>
                            <a href="" className="instagram"><i className="fa fa-instagram"></i></a>
                        </div>
                    </div>
                </footer>

                <script src="assets/js/jquery.min.js"></script>
                <script src="assets/js/bootstrap.bundle.min.js"></script>

                <script src="assets/js/main.js"></script>



            </body>
        </React.Fragment>
    )
}
export default Home;