import { createContext } from 'react'

const AppContext = createContext({
  user: null,
  hasLoginError: false,
  login: () => null,
  logout: () => null,
  loginErrorMessage: "",
})

export default AppContext