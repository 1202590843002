import React, { useEffect, useState, useContext } from "react";
import { connectWallet, getCurrentWalletConnected } from "../utils/connect.js";
import AppContext from '../context/app';

let BASE_URL = "https://api.rtalabs.xyz";
// BASE_URL = "http://localhost:7777";

function Login(props) {
  const [isBeingLoggedIn, setIsBeingLoggedIn] = useState(false);
  const [signature, setSignature] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);
  const [wallet, setWallet] = useState("");
  const [txStatus, setTxStatus] = useState("");
  const { login, hasLoginError, setAddressModalOpen } = useContext(AppContext)
  const { cta } = props;
  const getNonce = async (address) => {
    const response = await fetch(BASE_URL + '/api/auth/init', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-client-id': 'SASC',
      },
      body: JSON.stringify({
        "address": address
      })
    });
    return response.json();
  }

  const verifySignature = async (nonce, signature, address) => {
    const response = await fetch(BASE_URL + '/api/auth/verify', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-client-id': 'SASC',
      },
      body: JSON.stringify({
        "nonce": nonce,
        "signature": signature,
        "address": address
      })
    });
    const resp = await response.json();
    return {
      'verified': response.status == 200,
      'user': resp.user,
      'token': resp.token,
    }
  }


  const connectWalletPressed = async () => {
    if (isBeingLoggedIn) {
      return;
    }
    setIsBeingLoggedIn(true);
    try {
      setLoggingIn(true);
      let wallet = await connectWallet().catch((e) => {
        setIsBeingLoggedIn(false);
      });
      setWallet(wallet);
      if (!wallet) {
        const url = "https://metamask.app.link/dapp/stonedapez.club";
        window.open(url, '_blank');
        setIsBeingLoggedIn(false);
      } else {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        let message = "";
        let nonce = await getNonce(accounts[0]).catch((error) => {
          return;
        }).catch((e) => {
          setIsBeingLoggedIn(false);
        });
        if (nonce) {
          nonce = nonce['nonce'];
          const signature = await window.ethereum.request({
            method: 'personal_sign',
            params: [
              nonce,
              accounts[0]
            ]
          });
          setSignature(signature);
          let verifyResp = await verifySignature(nonce, signature, accounts[0]).catch((error) => {
            // no-op
          });
          setLoggingIn(false);
          setIsBeingLoggedIn(false);
          if (verifyResp && verifyResp.verified) {
            let email = "";
            let countryCode = ""
            let phone = "";
            if (verifyResp.user && verifyResp.user.addresses && verifyResp.user.addresses.length > 0) {
              email = verifyResp.user.addresses[0].email;
              countryCode = verifyResp.user.addresses[0].country_code;
              phone = verifyResp.user.addresses[0].phone;
            }
            const user = {
              'walletAddress': accounts[0],
              'email': email,
              'coutnryCode': countryCode,
              'phone': phone,
              'token': verifyResp.token
            }
            login(user, false);
          } else {
            alert("Verified NFT Buyers only!")
            login(null, null, true, "Verified NFT Buyers Only");
          }
        }
        setIsBeingLoggedIn(false);
      }
    } finally {
      setIsBeingLoggedIn(false);
    }
  }

  const addWalletListener = async () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", accounts => {
        setWallet(accounts.length > 0 ? accounts[0] : '');
      });
    }
  };

  useEffect(() => {
    const fetchWallet = async () => setWallet(await getCurrentWalletConnected());
    fetchWallet();
    addWalletListener();
  }, []);

  return (
    <div className="rounded-pill btn btn-primary btn-join" onClick={connectWalletPressed} >{cta}</div>
  );
};

export default Login;
