import Login from "./components/Login"

const reducer = (state, action) => {
    switch (action.type) {
        case 'login': {
            const { user, hasLoginError, loginErrorMessage } = action.payload
            if (hasLoginError) {
                return {
                    ...state,
                    hasLoginError: true,
                    user: null,
                    loginErrorMessage
                }
            } else {
                sessionStorage.setItem("user", JSON.stringify(user));
                return {
                    ...state,
                    hasLoginError: false,
                    user
                }
            }
        }
        case 'logout':
            sessionStorage.removeItem("user");
            return {
                ...state,
                user: null
            }
        default:
            throw new Error(`Invalid action type: ${action.type}`)
    }
}

export default reducer;