import React, { useContext } from "react";
import AppContext from "../context/app";

function Logout() {
  const { logout } = useContext(AppContext)

  const logoutPressed = () => {
    logout();
  };

  return (
    <div className="rounded-pill btn btn-primary btn-join" onClick={logoutPressed} >Logout</div>
  );
};

export default Logout;
