
import React, { useContext, useState, useEffect } from "react";
import AppContext from "../context/app";
import Login from '../components/Login';
import Logout from '../components/Logout';
import SASC from "../contracts/sasc.js";
import EASC from "../contracts/easc.js";
import { connectWallet, getCurrentWalletConnected } from "../utils/connect.js";

const mintL1 = (apeId, updateBalance) => {
    return async () => {
        let wallet = await getCurrentWalletConnected();
        let gas = await EASC.methods.mintL1FromSA(apeId).estimateGas({
            from: await getCurrentWalletConnected(),
            value: 0,
        });
        gas = Number.parseInt(gas * 1.1);
        let result = await EASC.methods.mintL1FromSA(apeId).send({
            from: wallet,
            value: 0,
            gasLimit: gas
        });
        console.error(result.transactionHash);
        if (result.transactionHash) {
            await updateBalance();
        }
    }
}
const mintL2 = (apeId, updateBalance, l2Price) => {
    return async () => {
        let wallet = await getCurrentWalletConnected();
        let gas = await EASC.methods.mintL2FromSA(apeId).estimateGas({
            from: await getCurrentWalletConnected(),
            value: l2Price * 1000000000000000000,
        });
        gas = Number.parseInt(gas * 1.1);
        let result = await EASC.methods.mintL2FromSA(apeId).send({
            from: wallet,
            value: l2Price * 1000000000000000000,
            gasLimit: gas
        });
        console.error(result.transactionHash);
        if (result.transactionHash) {
            await updateBalance();
        }
    }
}

function Ape({ apeId, l1Consumed, l2Consumed, updateBalance, l2Price }) {
    const imageUrl = "https://res.cloudinary.com/dd6p5cxht/image/upload/v1635604432/images/sasc/" + apeId + ".jpg";
    let l1ImageUrl = "https://res.cloudinary.com/dd6p5cxht/image/upload/v1635612467/images/easc/" + (l1Consumed - 1) + ".jpg";
    let l2ImageUrl = "https://res.cloudinary.com/dd6p5cxht/image/upload/v1635612467/images/easc/" + (l2Consumed - 1) + ".jpg";
    if (!l1Consumed) {
        l1ImageUrl = "assets/img/shrooms.png"
    }
    if (!l2Consumed) {
        l2ImageUrl = "assets/img/shrooms2.png"
    }
    let l2PriceBlock = (
        <React.Fragment>
            <h4 class="pt-2">Consume the free Level 2</h4>
            <p>NOTE : Once you consume your free E2 mushroom you need to pay 0.12 Eth for your other Apez in order to make them E2
            </p>
        </React.Fragment>
    );
    if (l2Price > 0) {
        l2PriceBlock = (
            <React.Fragment>
                <h4 class="pt-2">0.12 eth + GAS COST</h4>
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <div class="col-lg-3 p-4 rounded bg-offwhite">
                <img src={imageUrl} class="img-fluid" />
                <h4 class="mt-4">Stoned Apez #{apeId}</h4>
            </div>
            <div class="col-lg-8 offset-md-1 p-4 rounded bg-offwhite">
                <div class="row">
                    <div class="col-lg-4" style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                        <img src={l1ImageUrl} class="img-fluid" />
                        {!l1Consumed &&
                            <React.Fragment>
                                {/* <button class="btn btn-blue mt-4" onClick={mintL1(apeId, updateBalance)}>Consume Level 1</button> */}
                                {/* <h4 class="pt-2">0.0 eth + GAS COST</h4> */}
                                <h4 class="pt-2">Free evolution period is over!</h4>
                            </React.Fragment>
                        }
                        {l1Consumed &&
                            <React.Fragment>
                                <h4 class="pt-2">Enlightened L1 Apez - #{l1Consumed-1}</h4>
                            </React.Fragment>
                        }
                    </div>
                    <div class="col-lg-6 offset-md-1" style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                        <img src={l2ImageUrl} class="img-fluid" />
                        {!l2Consumed &&
                            <React.Fragment>
                                {/* <button class="btn btn-green mt-4" onClick={mintL2(apeId, updateBalance, l2Price)}>Consume Level 2</button> */}
                                {/* {l2PriceBlock} */}
                                <h4 class="pt-2">Free evolution period is over!</h4>
                            </React.Fragment>
                        }
                        {l2Consumed &&
                            <React.Fragment>
                                <h4 class="pt-2">Enlightened L2 Apez #{l2Consumed-1}</h4>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
function Enlightenment() {
    const [wallet, setWallet] = useState("");
    const [ownedApes, setOwnedApes] = useState({});
    const [ownedL1, setOwnedL1] = useState({});
    const [ownedL2, setOwnedL2] = useState({});
    const { user } = useContext(AppContext);
    const l2Price = 0.12;
    let l2PriceForMe = 0;
    let connectWalletBtn = (
        <nav className="nav-menu d-none d-lg-block">
            <ul>
                <li className="active">
                    <div className="col">
                        <Login cta="Connect Wallet to access Stoned Apez Labs" />
                    </div></li>
            </ul>
            <ul>
            </ul>
        </nav>
    );
    connectWalletBtn = (<div></div>);

    const updateBalance = async () => {
        try {
            let wallet = await getCurrentWalletConnected();
            let balance = await SASC.methods.balanceOf(wallet).call()
            console.error("SASC", balance);
            let balanceL1L2 = 0;
            try {
                balanceL1L2 = await EASC.methods.balanceOf(wallet).call()
            } catch (e) {
                console.error("EASC Error", e);
            }
            const _ownedApes = await getOwnedApes(balance, wallet);
            const [_ownedL1, _ownedL2] = await getOwnedL1L2(balanceL1L2, wallet);
            await getMapping(_ownedApes, _ownedL1, _ownedL2)
            return _ownedApes;
        } catch (e) {
            console.log(`Balance Retrieval Failed. ${e}`)
        }
    };

    const getMapping = async (apes, l1, l2) => {
        const _ownedL1 = {}
        const _ownedL2 = {}
        let wallet = await getCurrentWalletConnected();
        for (let i = 0; i < Object.keys(apes).length; i++) {
            const apeId = Object.keys(apes)[i];
            const l1Id = await EASC.methods.mutatedL1s(apeId).call();
            // if (l1Id > 0 && l1[l1Id - 1]) {
            //     _ownedL1[apeId] = l1Id;
            // }
            if (l1Id > 0) {
                _ownedL1[apeId] = l1Id;
            }
            const l2Id = await EASC.methods.mutatedL2s(apeId).call();
            // if (l2Id > 0 && l2[l2Id - 1]) {
            //     _ownedL2[apeId] = l2Id;
            // }
            if (l2Id > 0) {
                _ownedL2[apeId] = l2Id;
            }   
        }
        await setOwnedL1(_ownedL1);
        await setOwnedL2(_ownedL2);
        await setOwnedApes(apes);
    }

    const getOwnedApes = async (balance) => {
        const _ownedApes = {}
        for (let i = 0; i < balance; i++) {
            let tokenId = await SASC.methods.tokenOfOwnerByIndex(await getCurrentWalletConnected(), i).call();
            _ownedApes[tokenId] = true;
        }
        // setOwnedApes(_ownedApes)
        return _ownedApes;
    };

    const getOwnedL1L2 = async (balance) => {
        const _ownedL1 = {}
        const _ownedL2 = {}
        for (let i = 0; i < balance; i++) {
            let tokenId = await EASC.methods.tokenOfOwnerByIndex(await getCurrentWalletConnected(), i).call();
            if (tokenId < 6969) {
                _ownedL1[tokenId] = true;
            } else {
                _ownedL2[tokenId] = true;
            }
        }
        return [_ownedL1, _ownedL2];
    };

    const addWalletListener = async () => {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", async accounts => {
                await setWallet(accounts.length > 0 ? accounts[0] : '');
                await updateBalance();
            });
        }
    };

    useEffect(() => {
        const fetchWallet = async () => setWallet(await getCurrentWalletConnected());
        fetchWallet();
        updateBalance();
        addWalletListener();
    }, []);

    const l2Mushrooms = Math.floor(Object.keys(ownedApes).length / 6);
    if (l2Mushrooms <= Object.keys(ownedL2).length) {
        l2PriceForMe = l2Price;
    }

    let apeContent = Object.keys(ownedApes).map((apeId) => {
        const key = String(apeId) + String(l2PriceForMe);
        return <Ape key={key} apeId={apeId} l1Consumed={ownedL1[apeId]} l2Consumed={ownedL2[apeId]} updateBalance={updateBalance} l2Price={l2PriceForMe} />
    });
    return (
        <div>
            {/* <button type="button" className="mobile-nav-toggle d-lg-none">{!user && <Login cta="Connect Wallet" />}</button> */}
            <header id="header" className="">
                <div className="container d-flex align-items-center">
                    <h1 className="logo mr-4"><a href="/"></a></h1>
                    <a href="/" className="logo mr-auto"><img src="assets/img/logo.png" alt="" className="img-fluid" /></a>
                    <div className="form-group has-search mr-auto">
                    </div>
                    {!user && connectWalletBtn}
                    {user && <Logout />}
                </div>
            </header>
            <div id="hero">
                <div className="d-flex align-items-center">
                    <div className="container">
                        <img src="assets/img/5.png" className="img-fluid" />
                    </div>
                </div>
                <main id="main">
                    <section id="blog" class="about">
                        <div class="container">
                            
                            <div class="row content">
                                <div class="col-lg-18">
                                    <h1 class="text-center" style={{backgroundColor:"#f00", border: "1px solid #f00", borderRadius: "10px", margin: "20px", color: "palegreen"}}>Free evolution period is over!</h1>
                                    <h1 class="text-center">Enlighten Your Ape to Another Level 🚀</h1>
                                    <p class="mr-4 ml-4 pt-4 pb-4 text-center">
                                    </p><li>You are using a Chrome Browser with metamask plugin(on browser).
                                    </li><li>Minting will work on Mobile devices also
                                        You have your metamask working
                                        <p></p>
                                        <br />
                                        <div class="col-lg-6 offset-md-3 pt-4 pb-4 bg-white rounded">
                                            <h2 class="text-dark">HOW IT WORKS?</h2>
                                            <p class="pt-4 text-dark text-justify">
                                                You’re in the Stoned Apez Lab🧪, a place where different experiments with stoned Apez happen. Here you can eat mushroom 🍄 and get Elightened.
                                                <br /> <br />Till Thanksgiving 🦃, every Stoned Apez will be able to eat shrooms 🍄 of different level and get Enlightened. Claiming the E1 mushroom is free; you'll only have to pay for gas. One E2 will be free for each 6 in the wallet.<br /><br />You can only get a Enlightened Apez by having a Stoned Apez in your wallet. Once a Enlightened Apez has been claimed by a StonedApez, that token cannot be used to get another one.<br /><br /> Enlightened Apez will be revealed a couple of days. The unclaimed Enlightened Apez will be released into Saturn 🪐 Forest to enjoy their freedom and live a happy life with new members.<br /><br />
                                            </p>
                                            <a href="#blog1"><button class="btn btn-primary">Shrooms 🍄 for Enlightenment</button></a>
                                        </div>

                                    </li></div>
                            </div>
                        </div>
                    </section>
                    <section id="hero" class="teambannerbg">
                        <div class="container">

                            <div class="row d-flex align-items-center">
                                <div class="col-md-4 mx-auto">

                                    <h1><span class="font800"> Just like SASC but not quite! </span></h1>
                                    <div class="line mb-4"></div>
                                    <p>Enlightened Apez share traits with Stoned Apez. Enlightening your Apez will be different. So in theory, a Stoned Apez token can lead to a random Evolved Apez.<br />Enlightened Apez consists Theme, Expressions, Caps, Clothes, Accessories, Hairs etc.<br />These will go under separate project on Open Sea.
                                    </p></div>
                                <div class="col-md-4 mx-auto">
                                    <img src="assets/img/en1.png" class="img-fluid rounded" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="blog1">

                        {l2Mushrooms > 0 &&
                            <div class="col-md-6 offset-md-3 bg-warning rounded mt-4">
                                <h1 class="text-center text-white pt-4">🎉 You received {l2Mushrooms} 👻Free lvl 2 🍄Shroom</h1>
                                {/* <p class="text-center text-white pt-2 pb-4">You can use that to Enlighten your Ape to E2</p> */}
                            </div>
                        }

                        {Object.keys(ownedApes).length > 0 &&
                            <div class="container">
                                <div class="row mb-4">
                                    <div class="col-md-12 pb-4"><h2 class="pt-4 text-center">
                                        You Have {Object.keys(ownedApes).length} lvl 1 Mushroom 🍄 and {l2Mushrooms} free lvl 2 Mushroom 🤯</h2></div>

                                    {apeContent}

                                </div>
                            </div>
                        }
                        {
                            Object.keys(ownedApes).length == 0 &&
                            <h1 class="text-center text-black pt-4">... Looking for apez ... </h1>
                        }



                    </section>

                </main>

                <section class="faq_content collaps_style section_padding ft_font">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-sm-10">
                                <div class="section_tittle style_2">
                                    <h2 class="wow fadeInDown" data-wow-delay=".5s">General FAQ’s </h2>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <div class="faq_arrordion_content">
                                    <div class="accordion" id="faq_arrordion">
                                        <div class="card wow fadeInLeft" data-wow-delay=".4s">
                                            <div class="card-header" id="faq_tittle_2">
                                                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#faq_2" aria-expanded="false" aria-controls="faq_2">
                                                    Can I get one Enlightened Apez if I don’t have a Stoned Apez?
                                                </button>
                                            </div>
                                            <div id="faq_2" class="collapse" data-parent="#faq_arrordion">
                                                <div class="card-body">
                                                    Enlightened Apez are limited to Stoned Apez collectors. One Enlightened Apez per Stoned Apez. If you don’t have a Stoned Apez, you can get one from OpenSea or wait until Enlightened Apez are revealed and get one from the secondary market.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card wow fadeInLeft" data-wow-delay=".3s">
                                            <div class="card-header" id="faq_tittle_1">
                                                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#faq_1" aria-expanded="true" aria-controls="faq_1">
                                                    What happens to unminted Enlightened Apez?
                                                </button>
                                            </div>

                                            <div id="faq_1" class="collapse show" aria-labelledby="faq_tittle_1" data-parent="#faq_arrordion">
                                                <div class="card-body">
                                                    You can mint your Enlightened Apez till thanksgiving. The unclaimed Enlightened Apez will be released into Saturn Forest to enjoy their freedom and live a happy life.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card wow fadeInLeft" data-wow-delay=".5s">
                                            <div class="card-header" id="faq_tittle_3">
                                                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#faq_3" aria-expanded="false" aria-controls="faq_3">
                                                    How can I check if a Stoned Apez has been used?
                                                </button>
                                            </div>
                                            <div id="faq_3" class="collapse" aria-labelledby="faq_tittle_3" data-parent="#faq_arrordion">
                                                <div class="card-body">
                                                    You can check if a Stoned Apez has been used to claim a Enlightened Apez with our verifier tool. Be aware that someone can claim a Enlightened Apez right after you check its availability—we can only guarantee if a token has been claimed right at the moment you verify it.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card wow fadeInLeft" data-wow-delay=".6s">
                                            <div class="card-header" id="faq_tittle_4">
                                                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#faq_4" aria-expanded="false" aria-controls="faq_4">
                                                    Is there any utility for Evolved Apez?
                                                </button>
                                            </div>
                                            <div id="faq_4" class="collapse" aria-labelledby="faq_tittle_4" data-parent="#faq_arrordion">
                                                <div class="card-body">
                                                    We plan to have some activities like games and airdrops in which collectors of both, a Stoned Apez and a Stoned Apez, are eligible.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </section>

                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="line"></div>
                            </div>
                        </div>
                    </div>

                    <footer id="footer">


                        <div className="container py-4">

                            <div className="mr-md-auto text-center">
                                <div className="copyright">
                                    VERIFIED SMART CONTRACT ADDRESS:<a href="https://etherscan.io/address/0x6dFCB04b7d2aB2069d9ba81aC643556429EB2d55"> <strong><span className="turquoise">0x6dFCB04b7d2aB2069d9ba81aC643556429EB2d55</span></strong></a>.
                                </div>

                                <div className="copyright">
                                    For any query or collaboration reach out on <h5>help@stonedapez.club</h5>
                                </div>
                            </div>
                            <div className="social-links text-center text-md-center pt-3 pt-md-0">

                                <a href="https://discord.gg/5bxTStNdt8" className="discord"><i className="fa fa-discord"></i></a>
                                <a href="https://twitter.com/StonedApez" className="twitter"><i className="fa fa-twitter"></i></a>
                                <a href="" className="instagram"><i className="fa fa-instagram"></i></a>
                            </div>
                        </div>
                    </footer>

                    <script src="assets/js/jquery.min.js"></script>
                    <script src="assets/js/bootstrap.bundle.min.js"></script>

                    <script src="assets/js/main.js"></script>



                </div></div><nav className="mobile-nav d-lg-none">
                <ul>

                </ul>
            </nav><div className="mobile-nav-overly"></div></div>
    )
}
export default Enlightenment;