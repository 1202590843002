
import React, { useContext, useState } from "react";
import AppContext from "../context/app";
import Login from '../components/Login';
import Logout from '../components/Logout';

function Comic() {
    const [comicId, setComicId] = useState(0);
    const { user } = useContext(AppContext);
    const comicUrl = "/assets/img/" + (comicId + 1) + ".jpg";
    const decrementer = (id) => {
        return Math.abs(id - 1) % 8
    };
    const incrementer = (id) => {
        return (id + 1) % 8
    };
    const prevComic = () => {
        setComicId(decrementer);
    }
    const nextComic = () => {
        setComicId(incrementer);
    }
    const connectWalletBtn = (
        <nav className="nav-menu d-none d-lg-block">
            <ul>
                <li className="active">
                    <div className="col">
                        <Login cta="Connect Wallet to access Stoned Apez Labs" />
                    </div></li>
            </ul>
            <ul>
            </ul>
        </nav>
    );
    const comicSection = (
        <section className="projects">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12 col-12">
                    </div>
                    <div>
                        <div className="project-box">
                            <img src={comicUrl} key={comicUrl} className="img-fluid mb-4" />
                            <div className="line mb-4"></div>
                            <div className="row">
                                <div className="col">
                                    <div className="col">
                                        {comicId != 0 &&
                                            <button className="rounded-pill btn btn-primary btn-join" onClick={prevComic}>Prev Page</button>
                                        }
                                    </div>

                                </div>
                                <div className="col">
                                    <div className="comic-id">{comicId + 1} / 8 </div>
                                </div>
                                <div className="col">
                                    {comicId != 7 &&
                                        <button className="rounded-pill btn btn-primary btn-join" onClick={nextComic}>Next Page</button>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <a href="https://sasccomic.s3.us-east-2.amazonaws.com/SASC_V1.1.pdf" target="_blank">
                    <button className="rounded btn btn-primary btn-1">Download</button>
                </a>
            </div>
        </section>
    );
    return (
        <div><button type="button" className="mobile-nav-toggle d-lg-none">{!user && <Login cta="Connect Wallet" />}</button>
            <header id="header" className="">
                <div className="container d-flex align-items-center">
                    <h1 className="logo mr-4"><a href="/"></a></h1>
                    <a href="/" className="logo mr-auto"><img src="assets/img/logo.png" alt="" className="img-fluid" /></a>
                    <div className="form-group has-search mr-auto">
                    </div>
                    {!user && connectWalletBtn}
                    {user && <Logout />}
                </div>
            </header>
            <div id="hero">
                <div className="d-flex align-items-center">
                    <div className="container">
                        <img src="assets/img/co1.png" className="img-fluid" />
                    </div>
                </div>
                <section id="p2" className="p2">
                    <div className="container">

                        <div className="row content">
                            <div className="col-lg-12 col-md-12 col-12">

                                <div className="section-title text-left">
                                    <h1>Monthly Comics - Edition 1</h1>
                                    {!user &&
                                        <React.Fragment>
                                        <br />
                                        <ul>
                                            <li>You are using a Chrome Browser with metamask plugin(on browser).</li>
                                            <li>You have your metamask working</li>
                                            <li>Connect your wallet and enjoy the reading the comics</li>
                                        </ul>
                                        </React.Fragment>
                                    }
                                    <p></p>
                                </div>

                                {user && comicSection}

                            </div></div></div></section>


                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="line"></div>
                            </div>
                        </div>
                    </div>

                    <footer id="footer">


                        <div className="container py-4">

                            <div className="mr-md-auto text-center">
                                <div className="copyright">
                                    VERIFIED SMART CONTRACT ADDRESS:<a href="https://etherscan.io/address/0x6dFCB04b7d2aB2069d9ba81aC643556429EB2d55"> <strong><span className="turquoise">0x6dFCB04b7d2aB2069d9ba81aC643556429EB2d55</span></strong></a>.
                                </div>

                                <div className="copyright">
                                    For any query or collaboration reach out on <h5>help@stonedapez.club</h5>
                                </div>
                            </div>
                            <div className="social-links text-center text-md-center pt-3 pt-md-0">

                                <a href="https://discord.gg/5bxTStNdt8" className="discord"><i className="fa fa-discord"></i></a>
                                <a href="https://twitter.com/StonedApez" className="twitter"><i className="fa fa-twitter"></i></a>
                                <a href="" className="instagram"><i className="fa fa-instagram"></i></a>
                            </div>
                        </div>
                    </footer>

                    <script src="assets/js/jquery.min.js"></script>
                    <script src="assets/js/bootstrap.bundle.min.js"></script>

                    <script src="assets/js/main.js"></script>



                </div></div><nav className="mobile-nav d-lg-none">
                {/* <ul>
                    <li>
                        <a href="">
                            <div className="col">
                                <button className="rounded-pill btn btn-primary btn-join">Connect Wallet to access Stoned Apez Labs</button>
                            </div>
                        </a>
                    </li>
                </ul> */}
                <ul>

                </ul>
            </nav><div className="mobile-nav-overly"></div></div>
    )
}
export default Comic;