import './App.css';
import React, { useReducer } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";

import AppContext from './context/app'
import Reducer from './reducer';
import Home from './pages/Home';
import Comic from './pages/Comic';
import Enlightenment from './pages/Enlightenment';

const INITIAL_STATE = {
  user: sessionStorage.getItem("user") && JSON.parse(sessionStorage.getItem('user')),
  hasLoginError: false,
  loginErrorMessage: "",
}


function App() {
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE)
  const login = (user, hasLoginError, loginErrorMessage) => {
    dispatch({ type: 'login', payload: { user, hasLoginError, loginErrorMessage } })
  }
  const logout = () => {
    dispatch({ type: 'logout' })
  }
  const value = {
    user: state.user,
    hasLoginError: state.hasLoginError,
    login,
    logout,
  }

  return (
    <div className="App">
      <AppContext.Provider value={value}>
        <Router>
          <Switch>
            <Route path="/comics">
              <Comic />
            </Route>
            <Route path="/enlightenment">
              <Enlightenment />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </AppContext.Provider>
    </div>
  );
}

export default App;
