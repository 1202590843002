export const connectWallet = async () => {
    try {
        const addresses = await window.ethereum.request({
            method: "eth_requestAccounts"
        });

        return addresses[0];
    } catch (e) {
        console.error(e);
        return false;
    }
};

export const getCurrentWalletConnected = async () => {
    try {
        const addressArray = await window.ethereum.request({
            method: "eth_accounts"
        });
        return (addressArray.length > 0 ? addressArray[0] : '');
    } catch (e) {
        return false;
    }
};
